<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="mstTable"
          title="지게차/운반구 유형 목록"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          :selection="popupParam.type"
          rowKey="code"
          @rowDblclick="rowDblclick"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fork-lift-type-manage-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
  },
  data() {
    return {
      mstGrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '지게차/운반구 유형',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      selectedRows: {}
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getCodeMst();
    },
    getCodeMst() {
      this.$http.url = selectConfig.sys.code.mst.list.multi.url
      this.$http.type = 'GET';
      this.$http.param = {
        srchCodeGrpCd: 'HEQ_TYPE_CD',
        srchUseFlag: 'Y',
      }
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    select() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '지게차/운반구 유형을 지정하세요.', // 지게차/운반구 유형 유형을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
